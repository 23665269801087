<template>
  <div :class="`m-3 p-2 wrapper  wrapper--${bu}`">
    <Loading v-if="loading" />
    <h2 class="text-center" v-if="!loading">Wegbrengen - {{ bu }}</h2>
    <table v-if="!loading" :class="`w-100 table--default table__border--${bu}`">
      <thead :class="`table__head--${bu}`">
        <tr>
          <th class="table__head--text">Status</th>
          <th class="table__head--text">Item</th>
          <th class="table__head--text">Omschrijving</th>
          <th class="table__head--text">Aanleverdatum</th>
          <th v-if="checkroles(['deb_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
            class="table__head--text">Wegbrengen naar</th>
          <th v-if="checkroles(['deb_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
            class="table__head--text">Locatie</th>
          <th class="table__head--text">Chauffeur</th>
          <th class="table__data--right table__head--text">Kosten</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, key) in deliver_orders" :key="key" :class="`table__row--color`">
          <td class="table__cell--default">
            <div v-if="item.status == 200">
              <span class="dot dot--blue" v-b-tooltip.hover title="Ingepland"></span>

            </div>
            <div v-else>
              <span class="dot dot--red" v-b-tooltip.hover title="Nieuw"></span>
            </div>
          </td>
          <td class="table__cell--default">
            <ImageHover :itemnummer="item.itemnummer" :bu="bu" />
          </td>
          <td class="table__cell--default">{{ item.voertuig }}</td>
          <td class="table__cell--default">{{ item.aanleverdatum }}</td>
          <td v-if="checkroles(['deb_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
            class="table__cell--default">{{ item.bedrijf }}</td>
          <td v-if="checkroles(['deb_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
            class="table__cell--default">{{ item.locatie }}</td>
          <td class="table__cell--default">{{ item.transporteur }}</td>
          <td class="table__data--right table__cell--default">€ {{ item.kosten }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading";
import ImageHover from "@/components/ImageHover.vue"


export default {
  props: ["bu"],
  components: { Loading, ImageHover },
  data: () => ({
    deliver_orders: null,
    loading: true,
  }),
  created() {
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.loading = true;
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      const deliver_orders_url = `deliver-orders/${bu}`;
      request(deliver_orders_url, "GET").then(({ deliver_orders }) => {
        this.deliver_orders = deliver_orders;
        this.loading = false;
      });
    },


  },
};
</script>
